<template>
  <view class="content_tt">
    <view class="tcheaderCT022">
      <view class="tchT">
        <view>RS</view>
        <view>20.00</view>
        <view>dollar</view>
      </view>
      <view class="tchT2">
        <image src="../../static/zhong.png" style="width:50rpx;" mode="widthFix"/>
        <view class="chTy">14:51</view>
      </view>
    </view>
    <view class="cent_tc20">
      <view class="centertc">
        <view class="center_bo" :class="{'active':isactive==0}" @click="choice(0)">Codigo QR</view>
        <view class="center_bo" :class="{'active':isactive==1}" @click="choice(1)">Change do PIX</view>
      </view>
      <view v-if="isactive==0">
        <view class="centertct1"><span>Collection code: </span>This is the QR code of<span> xiaoyun</span>. Please scan
          it
        </view>
        <view class="imgLG">
          <image src="" style="border:1px solid #724ec6;width:300rpx;height:300rpx; border-radius:10rpx;"
                 mode="widthFix"/>
        </view>
        <button>Save QR code</button>
      </view>
      <view v-if="isactive==1">
        <view class="centertct1"><span>Collection codeFTAGB:</span>This is the QR code of<span>{{}}</span>. Please scan
          it
        </view>
        <view class="imgLG">
          <image src="" style="border:1px solid #724ec6;width:300rpx;height:300rpx; border-radius:10rpx;"
                 mode="widthFix"/>
        </view>
        <button @click="oderC">Save QR code</button>
      </view>
    </view>
    <view class="button_bott">Paguoi com sdcosso</view>

    <u-mask :show="show" @click="show = false">
      <view>Scan QR code for payment</view>
      <image src="" mode="widthFix" style="width:300rpx;height:300rpx;"/>
      <view>Long press to save QR code</view>
    </u-mask>
  </view>
</template>

<script>
export default {
  name: "qrCodeInfo",
  data() {
    return {
      isactive: 0,
      show: false,
    }
  },
  methods: {
    oderC() {
      this.show = true;
    },
    choice(type) {
      this.isactive = type;
    }
  }
}
</script>

<style>
.content_tt {
  background-color: rgba(200, 181, 245, 0.2);
  height: 100vh;
  padding: 10 rpx 30 rpx;
}

.tcheaderCT022 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tchT, .tchT2 {
  display: flex;
  align-items: center;
}

.tchT > view {
  margin: 5 rpx;
}

.tchT > view:nth-child(1) {
  font-size: 30 rpx;
  font-weight: 540;
}

.tchT > view:nth-child(2) {
  font-size: 45 rpx;
  font-weight: 700;
}

.tchT > view:nth-child(3) {
  background-color: #724ec6;
  font-size: 10 rpx;
  padding: 1 rpx 4 rpx;
  border-radius: 10 rpx;
  color: #fff;
}

.chTy {
  font-size: 34 rpx;
  color: #e71313;
}

.cent_tc20 {
  box-shadow: 2px 2px 10px rgba(200, 181, 245, 0.3);
  border-radius: 10 rpx;
  padding: 30 rpx 30 rpx 40 rpx;
  margin: 40 rpx 0 rpx 50 rpx;
}

.centertc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(200, 181, 245, 0.5);
  border-radius: 10 rpx;
}

.center_bo {
  border-radius: 10 rpx;
  background-color: rgba(200, 181, 245, 0.5);
  width: 50%;
  padding: 10 rpx 20 rpx;
  text-align: center;
  color: #724ec6;
}

.centertct1 {
  font-size: 30 rpx;
  margin: 10 rpx 0 rpx;
}

.centertct1 span {
  color: #724ec6;
  margin: 0 rpx 10 rpx;
}

.imgLG {
  padding: 40 rpx 25% 10 rpx;
}

button {
  width: 300 rpx;
  background-color: #724ec6;
  border-radius: 30 rpx;
  color: #fff;
  font-size: 30 rpx;
  height: 60 rpx;
  line-height: 60 rpx;
}

.button_bott {
  background-color: #724ec6;
  color: #fff;
  border-radius: 5 rpx;
  text-align: center;
  padding: 20 rpx 0 rpx;
  margin: 90 rpx 0 rpx;
}

.active {
  background-color: #724ec6;
  color: #fff;
}
</style>
